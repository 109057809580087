<template>
  <div class="index">
    <div class="title">业绩目标</div>
    <div class="box target-box">
      <div class="screen-box">
        <div>
          <label for="year">年份</label>
          <el-date-picker
            v-model="screenData.year"
            id="year"
            type="year"
            class="my-picker"
            value-format="yyyy"
            size="mini"
            placeholder="选择年">
          </el-date-picker>
        </div>
        <div > 
          <label for="name">姓名</label>
          <el-input
            class="my-input"
            placeholder="请输入姓名"
            id="name"
            size="mini"
            clearable
            v-model="screenData.user_name">
          </el-input>
        </div>
        <div>
          <label for="province">省份</label>
          <el-select v-model="screenData.province" clearable id="province" size="mini" placeholder="请选择">
            <el-option
              v-for="item in option_sf"
              :key="item.value"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button type="primary" size="mini" @click="get_sale_target_setting">
            查 询
          </el-button>
        </div>
      </div>
      <div class="table-box">
        <div>
          <el-button type="primary" size="mini" @click="addTarget">
            新 增
          </el-button>
          <el-button type="primary" size="mini" @click="dialogVisible = true">
            批量导入
          </el-button>
          <el-button type="primary" size="mini" @click="export_sale_target_setting">
            导 出
          </el-button>
        </div>
        <div class="table-el-box">
          <el-table
            :data="targeTableData"
            size="mini"
            height="500"
            border
            class="my-table"
            style="width: 100%">
            <!-- <el-table-column
              type="selection"
              width="55"
              align="center">
            </el-table-column> -->
            <el-table-column
              prop="user_name"
              label="姓名"
              align="center">
            </el-table-column>
            <el-table-column
              prop="province"
              label="省份"
              align="center">
            </el-table-column>
            <el-table-column
              label="业绩目标（万元）"
              align="center"
             >
              <el-table-column
                prop="year"
                label="年度"
                align="center">
              </el-table-column>
              <el-table-column
                prop="year_total"
                label="全年"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_01"
                label="1月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_02"
                label="2月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_03"
                label="3月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_04"
                label="4月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_05"
                label="5月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_06"
                label="6月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_07"
                label="7月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_08"
                label="8月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_09"
                label="9月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_10"
                label="10月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_11"
                label="11月"
                align="center">
              </el-table-column>
              <el-table-column
                prop="month_12"
                label="12月"
                align="center">
              </el-table-column>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center">
              <template slot-scope="scope">
                <span class="editText" @click="editTarget(scope.row)">编辑</span>
                <el-popconfirm
                  title="确定删除该条目标吗？"
                  @confirm="del_sale_target_setting(scope.row)"
                >
                  <span  slot="reference" class="editText" style="color:red;">删除</span>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog
      title="批量导入"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-upload
        class="upload-demo"
        ref="upload"
        :headers="headers"
        drag
        name="file"
        :on-success="handleAvatarSuccess"
        :limit="1"
        :auto-upload="false"
        :action="action"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          可以上传.csv、.xls、.xls文件，一次只能上传一个文件
        </div>
      </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import api from '@/api/other.js'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

export default {
  data() {
    return {
      screenData: {
        year: '',
        user_name: '',
        province: '',
      },
      exportData: {
      },
      option_sf: [],
      targeTableData: [],

      dialogVisible: false,
      headers: '',
      action: `${process.env.VUE_APP_BASE_API}/api/excel_sale_target_setting/`,
      
    }
  },
  
  created() {
    this.headers = {
      Authorization: `${getToken()}`,
    }
    this.option_sf = JSON.parse(localStorage.getItem('province'))
    this.get_sale_target_setting();
  },
  watch: {
    dialogVisible(value) {
      if(!value){
        this.$refs.upload?this.$refs.upload.clearFiles():'';
      }
    },
  },
  methods: {
    // 删除业绩
    del_sale_target_setting(row) {
      api.del_sale_target_setting(row).then(res => {
        Message.success(res.msg)
        this.get_sale_target_setting();
      })
    },
    // 获取业绩目标
    get_sale_target_setting() {
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.exportData = {... this.screenData}
      api.get_sale_target_setting(this.screenData).then(res => {
        this.targeTableData = res.data
      }).finally(msg => {
        loading.close();
      })
    },
    // 导入文件 手动上传
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 文件上传成功后的回调
    handleAvatarSuccess(res, file) {
      if(res.code == 200){
        this.dialogVisible = false;
        this.$refs.upload.clearFiles();
        Message.success(res.msg)
        this.get_sale_target_setting();
      }else {
        Message.warning(res.msg)
      }
    },
    // 导出业绩目标表格
    export_sale_target_setting() {
      api.excel_sale_target_setting(this.exportData).then(res => {
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = 'fileName'
        a.href = res.data.file_url
        a.dispatchEvent(event)
      })
    },
    // 跳转到新页面去修改业绩目标
    editTarget(row) {
      let query = {targetitem: JSON.stringify(row),isEdit:true }
      this.$router.push({
        path: '/setPerformance',
        query,
      })
    },
    // 跳转到新页面去新增业绩目标
    addTarget() {
      this.$router.push({
        path: '/setPerformance',
      })
    },
    
    // // 新增业绩目标
    // add_sale_target_setting() {
    //   api.post_sale_target_setting(this.targetAddList).then(res => {
    //     console.log(111)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
$gap: px(10);
.index {
  padding: $gap;
}
.title {
    padding-left: $gap;
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }

.box {
  background-color: #fff;
  padding: $gap;
  margin: $gap;
  border-radius: $gap/2;
  border: px(1) #eee solid;
}
.target-box{
  .screen-box {
    display: flex;
    padding: $gap 0;
    border-bottom: px(1) #eee solid;
    .my-picker {
      width: 11vw;
    }
    .my-input {
      width: 11vw;
      display: inline-block;
    }
    &>div {
      &>label {
        margin-right: px(10);
      }
      flex: 1;
    }
  }
}
.table-box{
  padding-top: $gap;
  ::v-deep .my-table {
    .cell {
      //padding: 0;
    }
  }
  .table-el-box {
    padding-top: $gap;
  }
  .editText {
    color: #169BD5;
    cursor: pointer;
  }
  .editText:hover {
    color: #409EFF;
    text-decoration:underline;
  }
}
.set-box {
 .item {
  display: flex;
  border-bottom: px(1) #eee solid;
  padding: $gap;
  .left {
    flex: 2;
    .left-top {
      width: 100% ;
      display: flex;
      justify-content: space-between;
      p {
        text-align: center;
        ::v-deep .my-input {
          .el-input__inner  {
            width:97% ;
          }
        }
      }
    }
  }
  .line {
    width: 1px;
    background-color: #eee;
    margin: $gap;
  }
  .content {
    flex: 7;
    &>div {
      display: flex;
      justify-content: space-between;
    }
    p {
      width:14%;
      text-align: center;
      ::v-deep .my-input {
        .el-input__inner  {
          width: 100% ;
        }
      }
    }
    .years-picker-box {
      ::v-deep .el-date-editor.el-input{
        width: 100%;
      }
    }
  }
  .right {
    //flex: 1;
    width: px(100);
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    &>span {
      cursor: pointer;
    }
  }
 }
 .add-box {
  padding: $gap;
 }
 

}
</style>